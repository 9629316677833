/*

## Buttons

*/

.btn {
    cursor: pointer;
}

.btn-primary,
.btn-secondary {
    font-family: "Montserrat", sans-serif;
    font-weight: $fw-semi-bold;
    font-size: $fs-large;
    color: $color-white;
    text-decoration: none;
    padding: rem(15);
    display: inline-flex;
    min-width: rem(274);
    justify-content: center;
}

.btn-primary {
    background-color: $color-red-03;
}

.btn-secondary {
    background-color: $color-black-01;
}
