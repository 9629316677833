#output {
    margin: 0 5px;
}
  
.pixelate {
    display: flex;
    justify-content: center;
    justify-items: center;
}
  
.image-workspace {
    display: flex;
    justify-content: space-evenly;
  
    &-box {
      width: 100%;
      max-width: 45vh;
      min-width: 500px;
      min-height: 500px;
      position: relative;
    }
  
    &-container {
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  
    .user-image {
        background-image: url("./img/original-image.jpg");
        background-size: cover;
  
        .user-img {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: grey;
        }
    }
  
    .pixel-preview {
        background-color: yellow;
  
        &-img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}
  
.cropper-drag-box {
    background-color: cornflowerblue;
    opacity: 0.8;
}
  
.background-clear {
    background-image: none !important;
}
  
.hidden {
    display: none;
    visibility: hidden;
}
  
.image-confirm {
    position: relative;
  
    &-description {
        position: absolute;
        top: 50px;
        left: 300px;
        width: 350px;
        height: 50px;
        background-color: aquamarine;
        display: inline;
    }
  
    &-button {
        float: right;
    }
}

.slider {
    padding-bottom: 50px;
}

.slider__container {
    padding-right: 5px;
}

.slider__img {
    box-sizing: border-box;
    max-width: 100%;
    max-height: 100%;
    width: 300px;
    height: 220px;
    cursor: pointer;
}
