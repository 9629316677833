// ===================================
// FONTS
// ===================================
//
// This file gathers all fonts variables.
//

@import '../mixins/all';

// Font weights
$fw-regular:      normal !default;
$fw-medium:       500 !default;
$fw-semi-bold:    600 !default;
$fw-bold:         700 !default;
$fw-extra-bold:   800 !default;

// Font Spacing - letter-spacing
$ls-xxxl:               0.1875rem !default;   // 3px
$ls-xxl:                rem(2) !default;      // 2px
$ls-xl:                 0.1187rem !default;   // 1.9px
$ls-large:              0.1rem !default;      // 1.6px
$ls-normal:             normal !default;
$ls-minus-08:           rem(-0.8px) !default;

// Line Height - Body
$lh-base:               1.5 !default;        // 24px
$lh-medium:             1.25 !default;       // 20px
$lh-small:              1.2 !default;
$lh-xs:                 1 !default;          // 16px

// Font Size - Body
$base16-14px:           0.875rem !default;   // 14px

$fs-super-large:        rem(64) !default;    // 64px
$fs-xxxl:               rem(40) !default;    // 40px
$fs-xxl:                rem(32) !default;    // 32px
$fs-xl:                 rem(24) !default;    // 24px
$fs-large:              rem(20) !default;    // 20px
$fs-medium:             rem(18) !default;    // 18px
$fs-base:               rem(16) !default;    // 16px
$fs-small:              rem(14) !default;    // 14px
$fs-xs:                 rem(12) !default;    // 12px
$fs-xxs:                rem(10) !default;    // 10px

// Font Spacing - letter-spacing
$font-spacing-base:         0 !default;
$font-spacing-btn:          0.0563rem !default;    // 0.9px
$font-spacing-input:        0.0625rem !default;    // 1px
$font-spacing-label:        0.0531rem !default;    // 0.85px

// Headings
$h1-font-size:                      $fs-medium;
$h2-font-size:                      $fs-large;
$h3-font-size:                      $fs-large;
$h4-font-size:                      $fs-medium;
$h5-font-size:                      $fs-base;
$h6-font-size:                      $fs-small;
