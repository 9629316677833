@use '../../../styles/global.scss' as *;

.language-picker {
    display: flex;
    align-items: center;
    gap: rem(4);

    &__item {

        &:first-child::after {
            content: '';
            border-right: rem(1.06) solid $color-black-01;
            padding-right: rem(4);
        }
    }

    &__label {
        font-family: "Montserrat", sans-serif;
        font-weight: $fw-medium;
        font-size: $fs-base;
        color: $color-black-01;
        cursor: pointer;

        @media screen and (min-width: 768px) {
            font-size: $fs-large;
        }
    }

    &__input {
        visibility: hidden;
        display: none;
    }

    &__input:checked + &__label {
        font-weight: $fw-extra-bold;
        color: $color-red-02;
    }

    &__input:not(:checked) + &__label:hover {
        background: $color-beige-02;
    }
}
