@use '../../styles/global.scss' as *;

.accordion-item {
    background-color: $color-beige-03;
    padding: 0 rem(6);

    &:not(:first-of-type) {
        border-top: 0;
    }

    &-title {
        width: 100%;
        margin: 0;
    }

    &-btn {
        display: flex;
        align-items: center;
        text-align: left;
        width: 100%;
        background-color: $color-beige-03;
        border: 0;
        padding: rem(5) 0;
        font-size: $fs-base;
        font-weight: $fw-semi-bold;
        cursor: pointer;
        user-select: none;
        text-transform: uppercase;

        &::after {
            content: '';
            flex-shrink: 0;
            width: rem(20);
            height: rem(20);
            margin-left: auto;
            background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cline%20x1%3D%226.57129%22%20x2%3D%226.57129%22%20y2%3D%2213%22%20stroke%3D%22%231C293C%22%20stroke-width%3D%222%22%2F%3E%3Cline%20x1%3D%2213%22%20y1%3D%226.57153%22%20y2%3D%226.57153%22%20stroke%3D%22%231C293C%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E");
            background-repeat: no-repeat;
            background-color: $color-white;
            background-position: center;
        }
    }

    &-container {
        transition: height 0.3s ease-in-out;
        overflow: hidden;
    }

    &-content {
        border-top: rem(1) solid $color-black-01;
        padding: rem(5) 0;
        font-weight: $fw-medium;
        font-size: $fs-base;
    }

    &.active &-btn::after {
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2219%22%20height%3D%2219%22%20viewBox%3D%220%200%2019%2019%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cline%20x1%3D%2213.8392%22%20y1%3D%224.64668%22%20x2%3D%224.6468%22%20y2%3D%2213.8391%22%20stroke%3D%22%231C293C%22%20stroke-width%3D%222%22%2F%3E%3Cline%20x1%3D%2213.738%22%20y1%3D%2213.8391%22%20x2%3D%224.54557%22%20y2%3D%224.64668%22%20stroke%3D%22%231C293C%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E");
    }
}
