@use '../../styles/global.scss' as *;

.faq-page {
    background-color: $color_pink-03;
    color: $color-black-01;
    font-family: "Lato", sans-serif;

    &__inner {
        margin: 0 auto;
        padding: rem(40) rem(20);
        max-width: rem(1380);

        @media screen and (min-width: 768px) {
            padding: rem(60);
        }

        @media screen and (min-width: 1024px) {
            padding: rem(40) rem(25);
        }
    }

    &__title {
        font-weight: $fw-extra-bold;
        font-size: $fs-xl;
        text-transform: uppercase;
        text-align: center;
        color: $color-black-01;
        padding-bottom: rem(12);
    }
}
