@use '../../../styles/global.scss' as *;

.homepage-banner {
    background-image: url('./img/homepage-banner-interior.png');
    background-size: 170% 100%;
    background-repeat: no-repeat;
    background-position: 20%;
    height: 100vh;
    max-height: rem(428);
    position: relative;

    @media screen and (min-width: 480px) and (max-width: 600px) {
        background-size: 125% 100%;
    }

    @media screen and (min-width: 601px) and (max-width: 767px) {
        background-size: 100% 100%;
    }

    @media screen and (min-width: 768px) {
        background-size: cover;
        background-position: initial;
        max-height: 100vh;
    }

    @media screen and (min-width: 1024px) {
        background-position: center;
    }

    &__content-wrap {
        position: absolute;
        z-index: 1;
        display: flex;
        bottom: 0;
        right: 0;
        padding: rem(30);
        justify-content: center;
        align-items: flex-end;
        text-align: center;

        @media screen and (max-width: 768px) {
            left: 0;
        }

        @media screen and (min-width: 768px) {
            display: flex;
            width: 55%;
            top: 0;
            padding: rem(80) rem(60);
            justify-content: flex-end;
            align-items: flex-end;
            text-align: right;
        }
    }

    &__content-item {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        gap: rem(30);

        @media screen and (min-width: 768px) {
            gap: rem(60);
        }
    }

    &__content-title {
        font-size: rem(32);
        font-family: "Lato", sans-serif;
        font-weight: $fw-extra-bold;
        text-transform: uppercase;
        color: $color-white;

        @media screen and (min-width: 768px) {
            font-size: rem(48);
        }

        @media screen and (min-width: 1200px) {
            font-size: $fs-super-large;
        }
    }

    &__buttons-container {
        display: flex;
        flex-direction: column;
        gap: rem(20);

        @media screen and (min-width: 768px) {
            align-items: flex-end;
        }  
    }

    &__button-item {
        display: flex;
        justify-content: center;
    }
}
