@use '../../styles/global.scss' as *;

.header {
    background-color: $color-beige-01;

    &__inner {
        display: grid;
        padding: rem(15) rem(20);
        grid-template-columns: 2fr 1fr 1fr rem(30);
        grid-gap: rem(10);
        max-width: rem(1380);
        margin: 0 auto;

        @media screen and (min-width: 420px) {
            grid-gap: rem(20);
        }

        @media screen and (min-width: 768px) {
            padding: rem(20) rem(60);
            grid-gap: rem(40);
        }

        @media screen and (min-width: 1024px) {
            padding: rem(30) rem(25);
            grid-template-columns: 1fr 3fr 70px;
        }
    }

    &__logo {
        margin: auto 0;
    }

    &__logo-link {
        display: flex;
    }

    &__logo-img {
        width: 100%;
        max-width: rem(120);

        @media screen and (min-width: 768px) {
            max-width: rem(220);  
        }

        @media screen and (min-width: 1024px) {
            max-width: rem(275);  
        }
    }

    &__nav {
        display: grid;

        @media screen and (min-width: 1024px) {
            grid-template-columns: 85% 15%;
        }
    }

    &__nav-list {
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: center;

        @media screen and (max-width: 1024px) {
            background-color: $color-beige-03;
            position: absolute;
            width: rem(130);
            top: rem(-81);
            z-index: 10;
        }

        &.active {
            top: rem(70);
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding: rem(10);
            margin: 0;

            @media screen and (min-width: 768px) {
                top: rem(81);
            }
        }
    }

    &__nav-list-item {
        margin: 0;

        @media screen and (min-width: 1024px) {
            margin: auto 0;
        }

        & + & {
            @media screen and (min-width: 1024px) {
                margin: auto 0 auto rem(40);
            }
        }
    }

    &__nav-list-link {
        text-decoration: none;
        font-family: "Montserrat", sans-serif;
        font-weight: $fw-semi-bold;
        font-size: $fs-large;
        color: $color-black-01;
        padding: rem(4);

        &:hover {
            background: $color-beige-02;
        }

        &:hover:active {
            color: $color-red-02;
            background-color: $color-beige-03;
        }

        @media screen and (min-width: 1024px) {
            &:hover:active {
                color: $color-red-02;
                background-color: $color-beige-01;
            }
        }
    }

    &__nav-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: rem(10);
        margin: 0;

        @media screen and (min-width: 420px) {
            grid-gap: rem(20);
        }

        @media screen and (min-width: 768px) {
            grid-gap: rem(40);
        }

        @media screen and (min-width: 1024px) {
            margin: rem(16) 0;
        }
    }

    &__nav-actions-item {
        list-style: none;
    }

    &__nav-actions-account-button {
        border: none;
        background-color: $color-beige-01;
        padding: 0;
        cursor: pointer;
    }

    &__nav-actions-account-dropdown {
        border: rem(1.02) solid $color-black-01;
        padding: rem(10);
        background-color: transparent;
    }

    &__nav-actions-account-dropdown-icon {
        display: flex;
        justify-content: center;
        margin: rem(10) 0;
    }

    &__nav-actions-account-dropdown-list {
        background-color: $color-beige-03;
        padding: rem(10);
        margin: 0;
        display: flex;
        gap: rem(18);
        flex-direction: column;
    }

    &__nav-actions-account-dropdown-link {
        font-family: "Montserrat", sans-serif;
        font-weight: $fw-semi-bold;
        font-size: $fs-large;
        color: $color-black-01;
        text-decoration: none;
        padding: rem(4);

        &:hover {
            background: $color-beige-02;
        }

        &:hover:active {
            color: $color-red-02;
            background-color: $color-beige-03;
        }
    }

    &__nav-actions-icon {
        &:hover {
            background: $color-beige-02;
        }

        &:hover:active {
            fill: $color-red-02;
            background-color: $color-beige-01;
        }
    }

    .account-icon {
        position: relative;
        top: rem(3);

        @media screen and (max-width: 768px) {
            width: rem(28);
        }
    }

    .cart-icon {
        position: relative;
        top: rem(4);

        @media screen and (max-width: 768px) {
            width: rem(25);
        }
    }

    &__language-selector {
        display: flex;
        align-items: center;
        gap: rem(4);
        justify-content: center;

        @media screen and (min-width: 768px) {
            justify-content: flex-end;
        }
    }

    &__language-selector-item {

        &:first-child {
            border-right: rem(2) solid $color-gray-02;
            padding-right: rem(4);
        }
    }

    &__language-selector-label {
        font-family: "Montserrat", sans-serif;
        font-weight: $fw-medium;
        font-size: $fs-base;
        color: $color-gray-02;
        cursor: pointer;

        &:first-child {
            border-right: rem(1.06) solid $color-gray-02;
            background-color: $color-brown-01;
        }

        @media screen and (min-width: 768px) {
            font-size: $fs-large;
        }
    }

    label:first-child {
        background-color: $color-brown-01;
    }

    &__language-selector-input {
        visibility: hidden;
        display: none;
    }

    &__language-selector-input:checked + &__language-selector-label {
        font-weight: $fw-bold;
        color: $color-brown-01;
    }

    &__language-selector-input:not(:checked) + &__language-selector-label:hover {
        border-bottom: rem(2) solid $color-brown-01;
        color: $color-gray-04;
    }

    &__nav-toggle {
        position: relative;
        right: 0;
        cursor: pointer;
        z-index: 20;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media screen and (min-width: 1024px) {
            display: none;
        }
    }

    &__nav-btn-toggle {
        border: 0;
        background-color: inherit;
        padding: 0;
        display: flex;
        cursor: pointer;
    }

    &__nav-btn-toggle-icon {

        &:hover {
            background: $color-beige-02;
        }

        &:hover:active {
            stroke: $color-red-02;
            background-color: $color-beige-01;
        }
    }

    .hamburger-icon {
        stroke: $color-black-01;
        width: rem(30);
        height: rem(30);

        @media screen and (min-width: 768px) {
            width: rem(34);
            height: rem(32);
        }
    }

    .close-icon {
        width: rem(24);
        height: rem(24);
        background: $color-beige-02;

        @media screen and (min-width: 768px) {
            width: rem(34);
            height: rem(32);
        }
    }
}
