// ===================================
// COLORS
// ===================================
//
// This file gathers all color variables.
//

// Global colors styleguide
$color-black:               #000 !default;
$color-white:               #fff !default;

$color-black-01:            #1c293c !default;
$color-gray-01:             #d9d9d9 !default;
$color-gray-02:             #373131 !default;
$color-gray-03:             #959595 !default;
$color-gray-04:             #808080 !default;

$color-red-01:              #ff0101 !default;
$color-red-02:              #a62a13 !default;
$color-red-03:              #6d2428 !default;
$color-blue-01:             #7bdbff !default;
$color_pink-01:             #f8eae7 !default;
$color_pink-02:             #d8b2ab !default;
$color_pink-03:             #fff8f4 !default;
$color-green-01:            #27ff04 !default;
$color-brown-01:            #8b594f !default;
$color-beige-01:            #CCB4A9 !default;
$color-beige-02:            rgba(255, 255, 255, 0.7);
$color-beige-03:            #e6dedc !default;
