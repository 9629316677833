@use '../../styles/global.scss' as *;

.footer {
    background-color: $color-beige-01;
    color: $color-black-01;

    &__inner {
        margin: 0 auto;
        padding: rem(40) rem(20);
        max-width: rem(1380);

        @media screen and (min-width: 768px) {
            padding: rem(60);
        }

        @media screen and (min-width: 1024px) {
            padding: rem(40) rem(25);
        }
    }

    &__content {
        display: grid;
        grid-template-areas: 
            'logo logo'
            'footer-nav social';
        padding-bottom: rem(44);
        
        @media screen and (min-width: 768px) {
            grid-template-areas: 
                'logo footer-nav social'
                'worktime footer-nav social';
            grid-template-columns: minmax(200px, 1fr) 2.5fr .5fr;
        }
    }

    &__logo {
        grid-area: logo;
        display: flex;
        justify-content: center;
        padding-bottom: rem(20);

        @media screen and (min-width: 768px) {
            justify-content: flex-start;
        }
    }

    &__logo-img {
        width: 100%;
        max-width: rem(165);
    }

    &__worktime {
        grid-area: worktime;
        display: none;

        @media screen and (min-width: 768px) {
            display: block;
            max-width: rem(200);
            padding-top: rem(20);
        }

        @media screen and (min-width: 1024px) {
            padding-top: rem(30);
        }
    }

    &__worktime-headline {
        display: flex;
        justify-content: center;
        padding-bottom: rem(15);
    }

    &__worktime-headline-title {
        font-family: "Montserrat", sans-serif;
        font-weight: $fw-semi-bold;
        font-size: $fs-medium;

        @media screen and (min-width: 1024px) {
            font-size: $fs-large;
        }
    }

    &__worktime-description-text {
        font-family: "Montserrat", sans-serif;
        font-weight: $fw-semi-bold;
        font-size: $fs-medium;

        @media screen and (min-width: 1024px) {
            font-size: $fs-medium;
        }
    }

    &__nav {
        grid-area: footer-nav;
        display: flex;
        flex-direction: column;
        gap: rem(10);

        @media screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: space-evenly;
        }
    }

    &__nav-list {
        padding: 0;
        margin: 0;
    }

    &__nav-item {
        & + & {
            padding-top: rem(10);
        }
    }

    &__nav-link {
        font-family: "Montserrat", sans-serif;
        font-weight: $fw-semi-bold;
        font-size: $fs-medium;
        text-decoration: none;
        color: $color-black-01;
        padding: rem(4);

        &:hover {
            background: $color-beige-02;
        }

        &:hover:active {
            color: $color-red-02;
            background-color: $color-beige-01;
        }

        @media screen and (min-width: 1024px) {
            font-size: $fs-large;
        }
    }

    &__social {
        grid-area: social;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media screen and (min-width: 768px) {
            align-items: flex-start;
        }
    }

    &__social-items {
        margin: 0;
        padding: 0;
    }

    &__social-item {
        display: flex;
        justify-content: center;

        & + & {
            padding-top: rem(6);
        }
    }

    .instagram-icon {
        width: rem(29);
        height: rem(24);
    }

    .youtube-icon {
        width: rem(29);
        height: rem(24);
    }

    .telegram-icon {
        width: rem(29);
        height: rem(24);
    }

    .facebook-icon {
        width: rem(17);
        height: rem(24);
    }

    &__content-bottom {
        display: flex;
        flex-direction: column;
        padding-top: rem(20);
        border-top: rem(1) solid $color-black-01;
        gap: rem(10);

        @media screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
        }

        @media screen and (min-width: 1024px) {
            padding-top: rem(30);
        }
    }

    &__security {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;

        @media screen and (min-width: 768px) {
            justify-content: flex-start;
        }
    }

    &__security-item {
        & + & {
            margin-left: rem(60);
        }
    }

    &__security-link {
        font-family: "Montserrat", sans-serif;
        font-weight: $fw-semi-bold;
        font-size: $fs-small;
        text-transform: capitalize;
        text-decoration: none;
        color: $color-black-01;
    }

    &__copyright {
        font-family: "Montserrat", sans-serif;
        font-weight: $fw-semi-bold;
        font-size: $fs-small;
        text-transform: capitalize;
        text-align: center;
    }
}