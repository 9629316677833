@use '../../styles/global.scss' as *;

.accordion {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: rem(12);

    @media screen and (min-width: 768px) {
        gap: rem(18);
    }
}
